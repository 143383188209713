.App {
  text-align: center;
}
.bg-red {
  align-items: center;
  border-radius: 7%;
  border-style: double;
  border-color: firebrick !important;
}
.bg-green {
  border-radius: 7%;
  border-style: double;
  border-color: forestgreen !important;
}
.list .row,
.header-row {
  color: rgb(0, 116, 44);
  display: flex;
}
.list .row .data-cell,
.data-header-cell {
  position: relative;
  width: auto !important;
}

.inc-dec {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.ui.red.mini.basic.icon.button.decrease:hover {
  background-color: red !important;
  color: white !important;
  transition: "background-color 0.3s, color 0.3s" !important;
}
.ui.green.mini.basic.icon.button.increased:hover {
  background-color: green !important;
  color: white !important;
  transition: "background-color 0.3s, color 0.3s" !important;
}

.db-select-segment {
  min-width: 100% !important;
  margin-bottom: 5px;
  margin-top: -5px;
  font-size: 12px;
}
.btn-select-segment,
.btn-default-scenario {
  display: block;
  margin: auto;
  width: 100%;
}
.group-by {
  min-width: 100% !important;
  margin-bottom: 5px;
  margin-top: -5px;
  font-size: 12px;
}
.ui .active .label {
  width: 100%;
}

.nav-grid {
  display: flex;
  align-items: center;
  flex: 1;
  margin: 10px 0;
  gap: 10px;
}
/* revo header */
.revo-grid[theme="default"] revogr-header .rgHeaderCell {
  background-color: #a7d0f1;
  color: #2b2626;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
}
/* table */
revo-grid {
  margin: 15px 0;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: auto;
  text-align: left;
  height: auto;
}

.revo-dropdown-list .dropdown-inner {
  min-width: 100px !important;
}
revogr-data .rgCell.align-right {
  text-align: center !important;
}

/* cells */
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,300,700);
.revo-grid[theme="default"] revogr-data .rgRow {
  border: 1px solid transparent;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: center !important;
}
.revo-grid[theme="default"] revogr-data .rgRow:hover {
  background-color: #ccc;
}
/* padding left and right for main calculation table*/
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.css-1idn90j-MuiGrid-root {
  padding: 0 !important;
  margin-top: 5px;
}

/*+ for expand*/
.rgCell input {
  color: #77b6ea;
  background-color: #fff;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  outline: none;
  border: none;
  margin: 0 10px;
  font-size: 17px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.attributesChanges {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.selectGroupAttributeChange {
  width: 150px !important;
}
.selectGroupAttributeChange,
.selectSkuAttributeChange {
  width: 230px;
}
.selectAttributeAttributeChange {
  min-width: 120px !important;
  height: fit-content;
  font-size: 14px;
  margin: 0 2px;
}
.ui.input > input {
  width: 130px !important;
  height: 38px;
}

/*summary*/
div.stretched.row {
  font-weight: 400;
  font-size: 20px !important;
}
.ui.tertiary.segment {
  /* background: linear-gradient(to top, #a7d0f1, transparent); */
  background-color: #ffffff;
  color: black;
  height: fit-content;
}
revogr-header .rgHeaderCell .header-content {
  font-weight: bold;
  text-align: center !important;
}

revogr-viewport-scroll .vertical-inner revogr-data,
revogr-viewport-scroll .vertical-inner revogr-overlay-selection {
  font-size: 16px;
  font-weight: 400;
  text-align: center !important;
}
/*end summary*/

/* main calculation and other buttons hover */
.ui.mini.basic.icon.right.floated.button:hover,
.ui.left.pointing.basic.label:hover,
.ui.blue.basic.button:hover {
  background-color: blue !important;
  color: white !important;
  font-weight: bold !important;
}
.ui.mini.basic.icon.right.floated.button {
  font-size: 13px;
}
