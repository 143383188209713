.tile {
  height: 500px;
}
#tp {
  width: 1500px;
  height: 2400px;
}
#drop {
  width: 20px;
  flex: 1;
}

.ContainerDrag {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
